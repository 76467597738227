import {useTranslation} from 'react-i18next';
import React, {useMemo} from 'react';
import {langOptions} from './LangSwitcher';

export function FormattedDate({
  date,
  ...props
}: React.ComponentPropsWithoutRef<'time'> & { date: string | Date }) {
  date = typeof date === 'string' ? new Date(date) : date
  const {i18n, } = useTranslation();
  const dateFormatter = useMemo(() => {
    const current = langOptions.find(opt => opt.code === i18n.language || opt.alias === i18n.language);
    return new Intl.DateTimeFormat(current.code, {
      year: 'numeric',
      month: current.code === 'en-US' ? 'short' : 'long',
      day: 'numeric',
      timeZone: 'UTC',
    })
  }, [i18n.language]);

  return (
    <time dateTime={date.toISOString()} {...props}>
      {dateFormatter && dateFormatter.format(date)}
    </time>
  )
}
