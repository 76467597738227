import React, {Fragment, Suspense, useState} from 'react'
import {Providers} from './app/Providers';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from "react-router-dom";
import {Index} from './app/Index';
import {Layout} from './components/Layout';
import {indexLoader} from './loaders/indexLoader';
import {queryClient} from './lib/const';



const router = createBrowserRouter([
    {
        element: <Layout />,
        children:[
            {
                path: "/",
                loader: indexLoader(queryClient),
                element: <Index />,
            }
        ]
    },
]);

export function App() {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <Providers>
            <Suspense fallback={"Loading..."}>
                <RouterProvider router={router} />
            </Suspense>
        </Providers>
    )
}