import {QueryClient} from 'react-query';

export const queryClient = new QueryClient()

export enum QueryKey {
    getTimeLineItems = "getTimelineItems",
}

export const subscribeUrl = process.env.NODE_ENV === 'development' ? "http://woocom.local/wp-json/bu-magic-links/v1/subscriptions" : "https://experts.pub/wp-json/bu-magic-links/v1/subscriptions";

