{
    "page_heading": "The founder's journey to",
    "page_colored_heading": "successful software",
    "page_description": "Follow the journey and discover whether this ambitious web developer will manage to create his software for more productive freelancers or fail miserably.",
    "get_updates": "Get updates",
    "email_address": "Email Address",
    "official_website": "Official Website",
    "website_url": "https://experts.pub",
    "language": "Language",
    "blog": "Blog",
    "blog_url": "https://experts.pub/resources",
    "sashe_vuchkov": "Sashe Vuchkov",
    "brought_to_you": "Brought to you by",
    "something_went_wrong": "Something went wrong. Please try again later...",
    "signing_you_up": "Signing you up...",
    "go_back": "Go Back",
    "confirmed_title": "Congrats!",
    "confirmed_body": "Your subscription was successfully confirmed...",
    "unsubscribe_title": "Bye!",
    "unsubscribe_body": "You were successfully unsubscribed..."
}