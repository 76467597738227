import { ThemeProvider } from 'next-themes'
import {QueryClientProvider} from 'react-query';
import {queryClient} from '../lib/const';

export function Providers({ children }: { children: React.ReactNode }) {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider attribute="class" disableTransitionOnChange>
                {children}
            </ThemeProvider>
        </QueryClientProvider>
    )
}