import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {timelineQuery} from '../queries/timelineQuery';
import {useNavigate, useParams} from 'react-router-dom';
import {TimelineItem} from '../lib/types';
import {Article, ContentWrapper, Img} from '../components/mdx';
import {useTranslation} from 'react-i18next';
import {Button} from '../components/Button';
import {Spinner} from '../components/Spinner';

const hasFeaturedImage = (item: TimelineItem) => {
    return item._embedded?.['wp:featuredmedia'][0].media_details.sizes.full.source_url;
}

const getFeaturedImageSrc = (item: TimelineItem) => {
    return item._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url || item._embedded['wp:featuredmedia'][0].media_details.source_url;
}

export const Index = () => {
    const {i18n} = useTranslation();
    const [page, setPage] = useState(1);
    const [lang, setLang] = useState(i18n.language);
    const [localData, setLocalData] = useState<{page: number, items: TimelineItem[]}>({page: 1, items: []});
    const params = useParams();
    const navigate = useNavigate();
    const { data, isLoading } = useQuery(timelineQuery({lang: lang, page: lang !== i18n.language ? 1 : page}));


    useEffect(() => {
        if (lang === i18n.language) {
            return;
        }

        setLang(i18n.language);
        setPage(1);
        setLocalData((d) => {
            return {page: 1, items: []};
        });
        navigate("#");
        return;

    }, [data, i18n.language]);

    useEffect(() => {
        if (!data || localData.page === data.nextPage || lang !== i18n.language) {
            return
        }

        setLocalData((d) => {
            return {page: data.nextPage, items: [...d.items, ...data.items]};
        });

        navigate(`#${data.items[0].slug}`);

    }, [data, i18n.language]);


    return <>
        {isLoading && <ContentWrapper>
            <Spinner>Loading...</Spinner>
        </ContentWrapper>}
        {localData?.items?.map(item => <Article key={item.id} id={item.slug} date={item.date} >
            {hasFeaturedImage(item) &&
                <Img src={getFeaturedImageSrc(item)} />
            }
            <h2>
                <a className="text-gray-900 dark:text-gray-100 text-2xl" href={`#${item.slug}`} dangerouslySetInnerHTML={{__html: item.title.rendered}} />
            </h2>
            <div className="text-gray-600 dark:text-gray-100" dangerouslySetInnerHTML={{__html: item.content.rendered}} />
        </Article>)}
        {data?.nextPage && <ContentWrapper>
            <Button variant="loadMore" onClick={() => data?.nextPage && setPage(data.nextPage)}>Load More</Button>
        </ContentWrapper>}

    </>;
}