import axios from "axios";
import {TimelineItem} from '../lib/types';
import {QueryKey} from '../lib/const';

const langMap: Record<string, number> = {
    "bg": 21,
    "bg-BG": 21,
    "en": 23,
    "en-US": 23,
}

export const timelineQuery = ({lang, page = 1, perPage = 21}: {
    lang: string,
    page?: number,
    perPage?: number,
}) => ({
        queryKey: [QueryKey.getTimeLineItems, lang, page],
        queryFn: async (): Promise<{nextPage: number, items: TimelineItem[]}> => {
            const response = await axios.get<TimelineItem[]>(
                `https://experts.pub/wp-json/wp/v2/ep-timeline-item?per_page=${perPage}&page=${page}&_embed=wp:featuredmedia&tags=${langMap[lang]}&`
            );

            const nextPage = page < response.headers['x-wp-totalpages'] ? page + 1 : null;
            return {nextPage, items: response.data} as {nextPage: number, items: TimelineItem[]};
        },
        enabled: !!lang,
        refetchOnWindowFocus: false,
        retryOnMount: false,
        retry: 1,
    });
