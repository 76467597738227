{
  "page_heading": "Пътят до успешен софтуер от",
  "page_colored_heading": "ново поколение",
  "page_description": "Ще успее ли този амбициозен програмист да създаде успешен софтуер за по-продуктивни фрийлансъри или ще му се наложи да потъне в дън земя от срам?",
  "get_updates": "Получи известие",
  "email_address": "Имейл Адрес",
  "official_website": "Официален сайт",
  "website_url": "https://experts.pub/bg",
  "language": "Език",
  "blog": "Блог",
  "blog_url": "https://experts.pub/bg/gresources",
  "sashe_vuchkov": "Саше Вучков",
  "brought_to_you": "Изработено с кеф от",
  "something_went_wrong": "Нещо се обърка. Моля, опитайте по-късно...",
  "signing_you_up": "Обработка на заявката...",
  "go_back": "Затвори",
  "confirmed_title": "Поздравления!",
  "confirmed_body": "Вашият абонамент за бюлетина беше потвърден успешно...",
  "unsubscribe_title": "Довиждане!",
  "unsubscribe_body": "Вие се отписахте успешно от бюлетина..."
}