import React, {PropsWithChildren} from 'react';
import logo from "../images/favicon.png";
import clsx from 'clsx';

export type Props = {
    variant: "default" | "light",
}

const variantsMap: Record<string, string> = {
    default: "",
    light: "text-white",
}

export const Spinner = ({children, variant = "default"}: PropsWithChildren<Props>) => {
    return (
        <div className="text-sm inline-flex justify-center antialiased items-center">
            <img src={logo} width={50} height={50} alt="loading..." className="w-5 inline-block mr-2 animate-spin"  />
            <div className={clsx("animate-pulse", variantsMap[variant])}>{children}</div>
        </div>
    );
}